<template>
  <b-row class="container p-0 m-0 " style="background-color:white">
    <!--b-col
      cols="0"
      md="4"
      class="image-col background-primary-color"
      :style="`background: url('${formImage}')`"
    /-->
    <b-col cols="12" md="12" class="px-1 pb-1 px-md-5 pb-md-5 h-100">
      <div class=" h-100 d-flex flex-column align-items-center justify-content-center">
        <div class="mb-2 d-flex flex-column align-items-center justify-content-center">
          <h2 class="mb-1 form-medium-3">
            {{ $t('form.answers-thankyou') }}
          </h2>
          <h5 class="mb-1 form-medium-3 mt-2">
            {{ translate(form.name) }}
          </h5>
          <b-badge pill class="my-25">
            {{ $t('form.type.'+this.form.type) }}
          </b-badge>
        </div>
        <!-- Score -->
        <div v-if="showScore" class="mb-2 d-flex flex-column align-items-center justify-content-center">
          <h5>
            {{ $t('form.your-score') + ': ' }}
          </h5>
          <h4>
            {{ yourScore + ' / ' + maxScore }}
          </h4>
          <div v-if="scoreToPass">
            <span v-if="hasPassed" class="text-green">
              {{ $t('form.exam-passed') }} 😁
            </span>
            <div v-else class="d-flex flex-column align-items-center justify-content-center">
              <div class="text-red">
                {{ $t('form.exam-failed') }} 😕
              </div> 
              <div>
                {{ ' (' + $t('form.you-approve-with') + ' ' + scoreToPass + ')' }}
              </div>
            </div>
          </div>
          <div class="mt-25">
            {{ $t('form.your-points', {yourPoints: yourPoints}) }}
            {{ $t('form.total-points', {totalPoints: totalPoints}) }}.
          </div>
          <div v-if="isCompletable" class="mt-25">
            {{ $t('form.completable-points', {completablePoints: completablePoints}) }}
          </div>
        </div>
        <!-- Button to go back to space -->
        <a
          class="btn btn-primary mt-2 zindex-1"
          variant="primary"
          :href="getCollectiveUrl(collective)"
        >
          <span>{{ $t('action.done') }}</span>
        </a> 
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import formDefaultImage from '@/assets/images/pages/FormsDefaultPhoto.svg';
import { getCollectiveUrl } from "@core/utils/collective";
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'FormSuccess',
  components: {
  },
  mixins: [],
  data() {
    return {
      isLoading: false,
      showMark: false,
    };
  },
  computed: {
    totalPoints() {
      return this.$route.query.totalPoints;
    },
    yourPoints() {
      return this.$route.query.yourPoints;
    },
    yourScore() {
      return this.$route.query.yourScore;
    },
    completablePoints() {
      return this.hasPassed ? (this.form.pointsWhenPassed ?? this.yourScore ) : 0;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    formKey() {
      return this.$route.params.id;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    form() {
      if (this.$store.getters.forms) {
        const firstForm = this.$store.getters.forms.unpaginated[0];
        if(firstForm && firstForm.key === this.formKey){
          return firstForm;
        }
      }
      return [];
    },
    isCompletable() {
      return this.form.isCompletable;
    },
    showScore(){
      //return this.yourScore;
      //console.log('this.scoreToPass', this.scoreToPass);
      return this.form?.type === 'exam' && this.yourScore;
    },
    scoreToPass() {
      return this.form.scoreToPass;
    },
    maxScore() {
      return this.form.maxScore ?? 10;
    },
    hasPassed() {
      return this.yourScore >= this.scoreToPass;
    },
    locales() {
      return this.$store.getters.locales;
    },
    availableLanguages() {
      return this.$store.getters.currentCollective.availableLanguages;
    },
    locale() {
      const locale = this.currentLocale;
      if (locale === 'en') {
        return 'en-US';
      }
      if (locale === 'es') {
        return 'es-ES';
      }
      return 'ca-CA';
    },
    currentLocale() {
      if (this.$route.query.code) {
        return this.$route.query.code;
      }
      return this.$store.state.locale.currentLocale;
    },
    formImage() {
      console.log('this.form', this.form);
      if (this.form?.imageURL) {
        console.log('This form has image');
        return getImageResource(this.form.imageURL);
      } 
      if (this.isOnboarding) {
        console.log('This form is onboarding');
        return this.onbordingImage[this.currentLocale];
      }
      console.log('This form has NO image');
      return formDefaultImage;
    }
  },
  watch: {
    // currentLocale(value) {
    //   return value;
    // },
  },
  async created() {
    this.isLoading = true;
    await this.fetchForm();
    this.isLoading = false;
  },
  methods: {
    async fetchForm() {
      await this.$store.dispatch('getItems', {
        itemType: 'forms',
        forceAPICall: true,
        requestConfig: { formKey: this.formKey },
        page: 1,
      });
    },
    getImageResource(image) {
      return getImageResource(image);
    },
    getCollectiveUrl(collective) {
      return getCollectiveUrl(collective);  //, "/welcome"
    },
    translate(field) {
      for (const row of Object.keys(field)) {
        if (field[row]?.length === 0 || !field[row]) {
          delete field[row];
        }
      }
      if (this.code) {
        return translateTranslationTable(this.code, field);
      }
      if (Object.values(this.availableLanguages).find((language) => language === this.currentLocale)) {
        return translateTranslationTable(this.currentLocale, field);
      }
      return translateTranslationTable(this.availableLanguages[0], field);
    }
  },
};
</script>
<style lang="scss" scoped>
 .container {
  height: calc(100vh - 4rem);
  width: 100vw;
  min-width: 100vw;
 }
 .image-col {
  background-color: lightgrey;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  border: 0;
  position: relative;
}
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
.scroll-bar::v-deep {
  .__bar-is-vertical {
    background: $gray-300 !important; // Essential important
  }
  .__panel {
    padding: 10px 0;
    margin-right: 0;
  }
  .__view {
    .placeholder {
      height: 75vh;
    }
  }
}
.text-green {
  color: green;
}
.text-red {
  color: red;
}
</style>
